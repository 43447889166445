body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
        "Droid Sans", "Helvetica Neue", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-color: #353535;
    color: #fff;
    overflow-y: scroll;

	background-image: url("Files/Images/backgrounds/background.jpg");
	background-position: center;
	background-attachment: fixed;
	background-size: cover;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

@font-face {
    font-family: "Jumbox";
    src: local("Jumbox"), url(./Files/Fonts/jumbox.woff) format("woff");
}

a {
	color: unset;
	text-decoration: unset;
}
