.MuiTabs-flexContainerVertical {
	align-items: center;
}

/* Set the colour of the underline for the selected tab */
.MuiTabs-indicator {
	background-color: #f9c23c !important;
	height: 1px !important;
}

/* Set the text colour for the prediction types tabs to white (rather than blue) */
.prediction-type-tabs .MuiTab-textColorPrimary.Mui-selected {
	color: #FFF;
}
